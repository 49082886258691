import {useEffect, useState} from "react";
import IODefinitionStore from "../../stores/IODefinitionStore";
import AdcItemKeys from "../../domain/AdcItemKeys";


/*
CHARGER_MOTOR_MOVE,
CHARGER_START,
CHARGER_POST_MOVE_INIT_WAIT,
CHARGER_BATTERY_CHARGING,
CHARGER_BATTERY_HEALTH_CHECK,
CHARGER_BATTERY_LOW,
CHARGER_LOW_VOLTAGE_MODE
 */

const DolphinDataDictionary = () => {
    const [ioDefinitions, setIoDefinitions] = useState(null) ;

    const [selectedFW, setSelectedFW] = useState(null) ;
    const [fwRevisions, setFWRevisions] = useState([]) ;

    const [adcItems, setAdcItems] = useState([]) ;
    const [analogueItems, setAnalogueItems] = useState([]) ;

    const [displayGroups, setDisplayGroups] = useState(false) ;

    const [targetItems, setTargetItems] = useState([]) ;
    const [selectedFwItems, setSelectedFwItems] = useState({}) ;
    const [selectedAdcItems, setSelectedAdcItems] = useState({}) ;
    const [selectedAnalogueItems, setSelectedAnalogueItems] = useState({}) ;

    useEffect(() => {
        const fetchData = async () => {
            let io = await IODefinitionStore.io(true) ;
            setIoDefinitions(io) ;
        }

        fetchData() ;
    }, []);

    useEffect(() => {
        if (ioDefinitions) {
            debugIO(ioDefinitions) ;
            buildIoView(ioDefinitions) ;
        }
        else {
            console.log("No IO Definitions available") ;
        }
    }, [ioDefinitions]);

    const forceRefreshIO = async () => {
        let io = await IODefinitionStore.io(true) ;
        setIoDefinitions(io) ;
    }

    const clearIoView = () => {
        setSelectedFW(null) ;
    }

    const buildIoView = (io) => {
        setSelectedFW(null)
        if (!io) {
            return ;
        }

        let fw = io.fw_maps
        if (fw) {
            let items = Object.values(fw) ;
            setFWRevisions(items) ;
        }

        let adc = Object.values(io.adc_item_map) ;
        setAdcItems(adc) ;

        let ai = Object.values(io.analogue_items) ;
        setAnalogueItems(ai) ;
    }


    const debugIO = (io) => {
        console.log("IO Definitions - ", io) ;
        //
        // let adcItems = io.adc_item_map ;
        // console.log("ADC Items - ", adcItems) ;
        //
        // let botLim1 = adcItems[AdcItemKeys.BOT_LIM1_BIT.key] ;
        // console.log("BOT_LIM1 - ", botLim1) ;
    }

    const lookupAdcItemForSelectedFw = (key) => {
        if (!ioDefinitions || !selectedFW) {
            return null ;
        }

        let adcItems = selectedFW.item_adc_map ;
        return adcItems[key] ;
    }

    const toggleSelectFw = (fw) => {
        let fwlist = {...selectedFwItems} ;
        let t = fwlist[fw.revision] ;
        if (t) {
            delete fwlist[fw.revision] ;
        }
        else {
            fwlist[fw.revision] = fw ;
        }

        setSelectedFwItems(fwlist) ;
    }

    const toggleSelectAdc = (adc) => {
        let adcList = {...selectedAdcItems} ;
        let t = adcList[adc.io_id] ;
        if (t) {
            delete adcList[adc.io_id] ;
        }
        else {
            adcList[adc.io_id] = adc ;
        }

        setSelectedAdcItems(adcList) ;
    }

    return (
        <div  className="content" >
            <h1>Trigger Builder</h1>
            <p>Build a new data trigger</p>
            <button className={"button search-button"} onClick={forceRefreshIO}>Refresh</button>

            <>
                <div>
                    <div className="section-header">Firmware Revisions</div>
                    <div style={{padding: '5px'}}>
                        <div className="item-container-list">
                            {fwRevisions.map((item, idx) => {
                                let selected = selectedFwItems[item.revision] ;
                                let cl = selected ? "item-container item-container-selected" : "item-container" ;
                                return (
                                    <div key={idx} className={cl} onClick={() => toggleSelectFw(item)}>
                                        <div>
                                            <div style={{padding: '2px'}}><b>{item.revision}</b></div>
                                            <div>{item.revision_name || "- no description -"}</div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>

                <div>
                    <div className="section-header">Digital On/Off Data Elements</div>
                    <div style={{padding: '5px'}}>
                        <div className="item-container-list">
                            {adcItems.map((item, idx) => {
                                let selected = selectedAdcItems[item.io_id] ;
                                let cl = selected ? "item-container item-container-selected" : "item-container" ;
                                return (
                                    <div key={idx} className={cl} onClick={() => toggleSelectAdc(item)}>
                                        <div>
                                            <div style={{padding: '2px'}}><b>{item.io_id}</b></div>
                                            <div>{item.desc || "- no description -"}</div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>

                <div>
                    <div className="section-header">Other Data Elements</div>
                    <div style={{padding: '5px'}}>
                        <div className="item-container-list">
                            {analogueItems.map((item, idx) => {
                                return (
                                    <div key={idx} className="item-container">
                                        <div>
                                            <div style={{padding: '2px'}}><b>{item.label} {item.is_lookup ? `[lookup]` : ``}</b></div>
                                            <div>Key: [{item.key}]</div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>

                {displayGroups &&
                    <>
                        <h4>Groups</h4>
                        {selectedFW.groups.map((group, idx) => {
                            return (
                                <div key={idx} className={"fw-group"}>
                                    <p><b>{group.name}</b><br/><i>{group.desc}</i></p>
                                    {group.io.map((io, idx) => {
                                        let item = lookupAdcItemForSelectedFw(io);
                                        if (!item) {
                                            return (
                                                <div key={idx}>
                                                    <p><b>{io} - Definition not found</b></p>
                                                </div>
                                            )
                                        }
                                        return (
                                            <div>
                                                <h5>{io}</h5>
                                                <div>ADC : {item.adc}</div>
                                                <div>BIT INDEX : {item.bit}</div>
                                            </div>
                                        )

                                    })}
                                </div>
                            )
                        })
                        }
                    </>
                }

            </>

            {/*<div style={{ height: '800px', overflowY : 'auto'}}>*/}
            {/*    {ioDefinitions && <code style={{whiteSpace: "pre-wrap"}}>{JSON.stringify(ioDefinitions, null, 2)}</code>}*/}


            {/*</div>*/}
        </div>
    )
}

export default DolphinDataDictionary;