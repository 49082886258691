import {useEffect, useState} from "react";
import IODefinitionStore from "../../stores/IODefinitionStore";
import AdcItemKeys from "../../domain/AdcItemKeys";


/*
CHARGER_MOTOR_MOVE,
CHARGER_START,
CHARGER_POST_MOVE_INIT_WAIT,
CHARGER_BATTERY_CHARGING,
CHARGER_BATTERY_HEALTH_CHECK,
CHARGER_BATTERY_LOW,
CHARGER_LOW_VOLTAGE_MODE
 */

const DolphinDataDictionary = () => {
    const [ioDefinitions, setIoDefinitions] = useState(null) ;

    const [selectedFW, setSelectedFW] = useState(null) ;
    const [fwRevisions, setFWRevisions] = useState([]) ;

    const [adcAItems, setAdcAItems] = useState([]) ;
    const [adcBItems, setAdcBItems] = useState([]) ;
    const [adcCItems, setAdcCItems] = useState([]) ;
    const [adcOutItems, setAdcOutItems] = useState([]) ;
    const [analogueItems, setAnalogueItems] = useState([]) ;

    const [displayGroups, setDisplayGroups] = useState(false) ;

    useEffect(() => {
        const fetchData = async () => {
            let io = await IODefinitionStore.io(true) ;
            setIoDefinitions(io) ;
        }

        fetchData() ;
    }, []);

    useEffect(() => {
        if (ioDefinitions) {
            debugIO(ioDefinitions) ;
            buildIoView(ioDefinitions) ;
        }
        else {
            console.log("No IO Definitions available") ;
        }
    }, [ioDefinitions]);

    const forceRefreshIO = async () => {
        let io = await IODefinitionStore.io(true) ;
        setIoDefinitions(io) ;
    }

    const clearIoView = () => {
        setSelectedFW(null) ;
    }

    const buildIoView = (io) => {
        setSelectedFW(null)
        let fw = io.fw_maps
        if (fw) {
            let items = Object.values(fw) ;
            setFWRevisions(items) ;

        }
    }

    const buildSelectedFwView = (fw) => {
        setSelectedFW(fw) ;
        if (fw) {
            let am = Object.values(fw.item_adc_map) ;
            let adcA = am.filter((a) => a.adc === "A") ;
            let adcB = am.filter((a) => a.adc === "B") ;
            let adcC = am.filter((a) => a.adc === "C") ;
            let adcOut = am.filter((a) => a.adc === "O") ;

            setAdcAItems(adcA) ;
            setAdcBItems(adcB) ;
            setAdcCItems(adcC) ;
            setAdcOutItems(adcOut) ;

            setAnalogueItems(Object.values(fw.analogue_items)) ;
        }
    }

    const debugIO = (io) => {
        console.log("IO Definitions - ", io) ;
        //
        // let adcItems = io.adc_item_map ;
        // console.log("ADC Items - ", adcItems) ;
        //
        // let botLim1 = adcItems[AdcItemKeys.BOT_LIM1_BIT.key] ;
        // console.log("BOT_LIM1 - ", botLim1) ;
    }

    const lookupAdcItemForSelectedFw = (key) => {
        if (!ioDefinitions || !selectedFW) {
            return null ;
        }

        let adcItems = selectedFW.item_adc_map ;
        return adcItems[key] ;
    }

    return (
        <div>
            <h4>Dolphin Data Dictionary</h4>
            <p>Data currently available to Dolphin</p>
            <button className={"button search-button"} onClick={forceRefreshIO}>Refresh</button>

            {fwRevisions.map((fw, idx) => {
                    return (
                        <div key={idx} className={"fw-revision"}>
                            <p><b>[{fw.revision}] - {fw.revision_name}</b><br/>{fw.revision_desc}</p>
                            <button className={"button search-button"} onClick={() => buildSelectedFwView(fw)}>View</button>
                        </div>
                    )
                }
            )}

            {(selectedFW) &&
                <>
                    <div className="item-container-list">
                        <div>
                            <h2>Digital On/Off Data Elements</h2>
                            <div className="item-container-list">
                                <div style={{padding: '5px'}}>
                                    <div className="section-header">ADC A Items</div>
                                    {adcAItems.map((item, idx) => {
                                        return (
                                            <div key={idx} className={"fw-item"}>
                                                <b>{item.io_id}</b> <i>bit={item.bit}</i>
                                                <div>{item.desc || "- no description -"}</div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>

                                <div style={{padding: '5px'}}>
                                    <div className="section-header">ADC B Items</div>
                                    {adcBItems.map((item, idx) => {
                                        return (
                                            <div key={idx} className={"fw-item"}>
                                                <b>{item.io_id}</b> <i>bit={item.bit}</i>
                                                <div>{item.desc || "- no description -"}</div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>

                                <div style={{padding: '5px'}}>
                                    <div className="section-header">ADC C Items</div>
                                    {adcCItems.map((item, idx) => {
                                        return (
                                            <div key={idx} className={"text-container"}>
                                                <b>{item.io_id}</b> <i>bit={item.bit}</i>
                                                <div>{item.desc || "- no description -"}</div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>

                            </div>
                        </div>

                        <div>
                            <h2>Other Data Elements</h2>
                            <div  style={{padding: '5px'}}>
                                {analogueItems.map((item, idx) => {
                                    return (
                                        <div  key={idx} style={{paddingTop: '10px'}} >
                                            <div className="section-header">{item.label} {item.is_lookup ? `[lookup]` : ``}</div>
                                            <div>Key: [{item.key}]</div>
                                            {(item.is_lookup) &&
                                                <ul className={"lookup-list"}>
                                                    {Object.keys(item.lookup).map((k, idx) => {
                                                        let lu = item.lookup[k];
                                                        return (
                                                            <li key={idx}><b>[{k}] - </b>{lu || "- no description -"}</li>
                                                        )
                                                    })
                                                    }

                                                </ul>
                                            }
                                        </div>
                                    )
                                })
                                }
                            </div>

                        </div>
                    </div>


                    {displayGroups &&
                        <>
                            <h4>Groups</h4>
                            {selectedFW.groups.map((group, idx) => {
                                return (
                                    <div key={idx} className={"fw-group"}>
                                        <p><b>{group.name}</b><br/><i>{group.desc}</i></p>
                                        {group.io.map((io, idx) => {
                                            let item = lookupAdcItemForSelectedFw(io);
                                            if (!item) {
                                                return (
                                                    <div key={idx}>
                                                        <p><b>{io} - Definition not found</b></p>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div>
                                                    <h5>{io}</h5>
                                                    <div>ADC : {item.adc}</div>
                                                    <div>BIT INDEX : {item.bit}</div>
                                                </div>
                                            )

                                        })}
                                    </div>
                                )
                            })
                            }
                        </>
                    }

                </>
            }


            {/*<div style={{ height: '800px', overflowY : 'auto'}}>*/}
            {/*    {ioDefinitions && <code style={{whiteSpace: "pre-wrap"}}>{JSON.stringify(ioDefinitions, null, 2)}</code>}*/}


            {/*</div>*/}
        </div>
    )
}

export default DolphinDataDictionary;